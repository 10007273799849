import * as Utils from './Utils';

const BASE_URL = `${process.env.REACT_APP_API}/public/${process.env.REACT_APP_API_VERSION}/${process.env.REACT_APP_BACKEND_SERVICE}/user`;

// TODO read status code
export const recoverSession = (r) =>
    Utils.fetcher(`${BASE_URL}/recover?session_id=${r}`);

export const readData = () =>
    Utils.fetcher(`${BASE_URL}/cw/read/data`, {
        method: 'POST',
        body: JSON.stringify({}),
    });

export const writeData = (data) =>
    Utils.fetcher(`${BASE_URL}/cw/write/data`, {
        method: 'POST',
        body: JSON.stringify(data),
    });

export const sendBoardingPass = (data) =>
    Utils.fetcher(`${BASE_URL}/cw/send/boardingPass`, {
        method: 'POST',
        body: JSON.stringify(data),
    });

export const signOut = () =>
    Utils.fetcher(
        `${BASE_URL}/signout`,
        {
            method: 'POST',
        },
        false
    );
