import { useEffect, useState } from 'react';

import plane from 'images/plane.svg';
import seat from 'images/seat.svg';
import luggage from 'images/luggage.svg';

import { deserialize } from 'utils/serialization';
import { Main as Provider } from 'component/context';
import { Panel } from 'component/common';
import { sendBoardingPass } from 'component/api/User';

import S from 'theme/structure';
import { formatDate } from 'utils/dates';

const {
    Confirmation: { OnlineCheckin: OnlineCheckinStyles },
} = S.Flight;

const {
    Services: { Service: ServiceStyles },
} = OnlineCheckinStyles;

const CheckInStates = ['start', 'security', 'services'];

const RadioYesNo = ({ question, id }) => (
    <OnlineCheckinStyles.Form.RadioQuestion.Container key={id}>
        <OnlineCheckinStyles.Form.RadioQuestion.Question>
            {question}
        </OnlineCheckinStyles.Form.RadioQuestion.Question>
        {['Yes', 'No'].map((option) => (
            <OnlineCheckinStyles.Form.RadioQuestion.Radio
                key={`${id}-${option}`}
                type="radio"
                label={option}
                name={id}
                id={`${id}-${option}`}
            />
        ))}
    </OnlineCheckinStyles.Form.RadioQuestion.Container>
);

const Service = ({ name, triggerName, description, icon, tag, value }) => (
    <ServiceStyles.Container>
        <ServiceStyles.Icon.Container>
            <ServiceStyles.Icon.Image
                src={icon}
                alt={name}
            ></ServiceStyles.Icon.Image>
        </ServiceStyles.Icon.Container>
        <ServiceStyles.Body.Container>
            <ServiceStyles.Body.Title>{name}</ServiceStyles.Body.Title>
            {description && (
                <ServiceStyles.Body.Description>
                    {description}
                </ServiceStyles.Body.Description>
            )}
            {value && (
                <ServiceStyles.Body.Value.Container>
                    <ServiceStyles.Body.Value.Content>
                        {value}
                    </ServiceStyles.Body.Value.Content>
                    {tag && (
                        <ServiceStyles.Body.Value.Tag
                            bg="success-light"
                            text="success"
                        >
                            {tag}
                        </ServiceStyles.Body.Value.Tag>
                    )}
                </ServiceStyles.Body.Value.Container>
            )}
        </ServiceStyles.Body.Container>
        <ServiceStyles.Trigger.Container>
            <ServiceStyles.Trigger.Button
                className="button__rounded"
                variant="outline-secondary"
            >
                {triggerName}
            </ServiceStyles.Trigger.Button>
        </ServiceStyles.Trigger.Container>
    </ServiceStyles.Container>
);

const Component = () => {
    const { user, companies } = Provider.useContext();
    const [flight, setFlight] = useState(undefined);
    const [egg, setEgg] = useState(false);
    const [counter, setCounter] = useState(0);
    const [boardingPassSent, setBoardingPassSent] = useState(false);

    const [onlineCheckinCompleted, setOnlineCheckinCompleted] = useState(false);

    const [onlineCheckinPage, setOnlineCheckinPage] = useState(0);

    useEffect(() => {
        if (counter === 5) {
            setEgg(!egg);
            setCounter(0);
        }
    }, [counter, egg]);

    useEffect(() => {
        if (!flight) {
            const parameters = new URLSearchParams(window.location.search);
            const flight = parameters.get('flight');

            if (flight) {
                try {
                    const f = deserialize(flight);
                    f.flight.logo = companies[f.flight.company].image;
                    setFlight(f);
                } catch (e) {
                    console.error('Invalid flight', e);
                }
            }
        }
    }, [flight, companies]);

    useEffect(() => {
        if (onlineCheckinCompleted && !boardingPassSent) {
            setBoardingPassSent(true);
            sendBoardingPass(flight);
        }
    }, [onlineCheckinCompleted, boardingPassSent, flight, user]);

    const handleCheckinNext = () => {
        const next = onlineCheckinPage + 1;
        if (next < CheckInStates.length) {
            setOnlineCheckinPage(next);
        } else {
            setOnlineCheckinCompleted(true);
        }
    };

    const handleCheckinBack = () => {
        const next = onlineCheckinPage - 1;
        if (next >= 0) {
            setOnlineCheckinPage(next);
        }
    };

    const OnlineCheckin = () => {
        if (!user) return <></>;

        if (!user?.preferences) return <></>;

        if (onlineCheckinCompleted) return <></>;

        const pages = {
            start: (
                <>
                    <OnlineCheckinStyles.Title>
                        Online check-in is now available
                    </OnlineCheckinStyles.Title>
                    <OnlineCheckinStyles.Button
                        className="button__rounded"
                        onClick={handleCheckinNext}
                    >
                        Continue
                    </OnlineCheckinStyles.Button>
                </>
            ),
            security: (
                <>
                    <OnlineCheckinStyles.Form.Container>
                        {[
                            'Are you carrying any liquids, aerosols or gels in your carry-on baggage?',
                            'Could anyone have interfered with your baggage?',
                        ].map((question) => (
                            <RadioYesNo
                                key={question}
                                question={question}
                                id={question}
                            />
                        ))}
                    </OnlineCheckinStyles.Form.Container>
                    <OnlineCheckinStyles.Button
                        className="button__rounded"
                        onClick={handleCheckinNext}
                    >
                        Continue
                    </OnlineCheckinStyles.Button>
                </>
            ),
            services: (
                <>
                    <OnlineCheckinStyles.Services.Container>
                        {[
                            {
                                name: 'Seat selection',
                                triggerName: 'Change',
                                icon: seat,
                                tag: 'Based on your preferences',
                                value: flight.ticket.seat,
                            },
                            {
                                name: 'Need more luggage?',
                                triggerName: 'Add luggage',
                                icon: luggage,
                                description:
                                    'Take advantage of discounts when you buy extra luggage online.',
                            },
                        ].map((service) => (
                            <Service key={service.name} {...service} />
                        ))}
                    </OnlineCheckinStyles.Services.Container>
                    <OnlineCheckinStyles.Button
                        className="button__rounded"
                        onClick={handleCheckinNext}
                    >
                        Continue
                    </OnlineCheckinStyles.Button>
                </>
            ),
        };

        return (
            <OnlineCheckinStyles.Container>
                {pages[CheckInStates[onlineCheckinPage]]}
            </OnlineCheckinStyles.Container>
        );
    };

    const Confirmation = () => {
        if (flight) {
            const {
                Confirmation: { Container, Icon, Title, Info },
            } = S.Flight;

            let confirmationStatus = 'main';
            if (onlineCheckinCompleted) {
                confirmationStatus = 'checkedin';
            } else if (CheckInStates[onlineCheckinPage] !== 'start') {
                confirmationStatus = 'onlinecheckin';
            }

            let title = '';
            let displayIcon = true;
            let displayInfo = true;
            let displayGoBack = false;
            let displaySeat = false;
            switch (confirmationStatus) {
                case 'main':
                    title = 'Your booking is confirmed!';
                    break;
                case 'checkedin':
                    title = "You're checked in!";
                    displaySeat = true;
                    break;
                case 'boarded':
                    title = "You're ready to fly";
                    displaySeat = true;
                    break;
                case 'onlinecheckin':
                    displayIcon = false;
                    displayInfo = false;
                    displayGoBack = true;
                    if (CheckInStates[onlineCheckinPage] === 'security') {
                        title = 'Security questions';
                    } else if (
                        CheckInStates[onlineCheckinPage] === 'services'
                    ) {
                        title = 'Additional services';
                    }
                    break;
                default:
                    break;
            }

            const generalInfo = (
                <>
                    {displayIcon && (
                        <Icon onClick={() => setCounter(counter + 1)} />
                    )}
                    <Title.Container>
                        {displayGoBack && (
                            <Title.Back onClick={handleCheckinBack} />
                        )}
                        <Title.Content>{title}</Title.Content>
                    </Title.Container>
                    {displayInfo && (
                        <Info.Container>
                            <Info.Row.Container>
                                <Info.Row.Label>Name</Info.Row.Label>
                                <Info.Row.Value.Container>{`${flight.passenger.firstName} ${flight.passenger.lastName}`}</Info.Row.Value.Container>
                            </Info.Row.Container>
                            <Info.Row.Container>
                                <Info.Row.Label>Departure</Info.Row.Label>
                                <Info.Row.Value.Container>{`${formatDate({
                                    date: new Date(flight.date),
                                })}`}</Info.Row.Value.Container>
                            </Info.Row.Container>
                            <Info.Row.Container>
                                <Info.Row.Label>Flight</Info.Row.Label>
                                <Info.Row.Value.Container>
                                    <>
                                        <Info.Row.Value.Icon
                                            src={plane}
                                            alt="plane"
                                        />
                                        {`${flight.flight.departure.airport.name} to ${flight.flight.arrival.airport.name}`}
                                    </>
                                </Info.Row.Value.Container>
                            </Info.Row.Container>
                            {displaySeat && (
                                <Info.Row.Container>
                                    <Info.Row.Label>Seat</Info.Row.Label>
                                    <Info.Row.Value.Container>
                                        <>
                                            <Info.Row.Value.Icon
                                                src={seat}
                                                alt="seat"
                                            />
                                            {flight.ticket.seat}
                                        </>
                                    </Info.Row.Value.Container>
                                </Info.Row.Container>
                            )}
                        </Info.Container>
                    )}
                </>
            );
            return (
                <Panel>
                    <Container className="centered">
                        {generalInfo}
                        <OnlineCheckin />
                    </Container>
                </Panel>
            );
        }

        return <Panel />;
    };

    return (
        <S.Container.Page>
            <Confirmation />
        </S.Container.Page>
    );
};

export default Component;
